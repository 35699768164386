<template>
	<div style="width: 100%">
		<a-spin :spinning="spinning">
			<div class="clearfix">
				<a-upload :action="UpLoadUrl" :default-file-list="defaultFileList" :remove="handleRemove" @change="handleUpload" :before-upload="beforeUpload">
					<a-button>
						<a-icon type="upload" />
						上传
					</a-button>
				</a-upload>
			</div>
		</a-spin>
	</div>
</template>

<script>
import request, { UpLoadUrl } from '@/config/request';
import utils from '@/common/utils';
export default {
	data() {
		return {
			UpLoadUrl: UpLoadUrl(),
			file: null,
			uploading: false,
			fileList: [],
			result: [],
			spinning: false,
			i: 1,
			defaultFileList: []
		};
	},

	methods: {
		handleRemove(file) {
			// file.status = 'removed';
		},
		beforeUpload(file) {
			this.file = file;
			return false;
		},
		handleUpload(info) {
			const formData = new FormData();
			formData.append('file', this.file);

			this.i = this.i + 1;
			this.spinning = true;
			this.http
				.post(this.UpLoadUrl, formData)
				.then(r => {
					if (r.code == 200) {
						r.data.uid = this.i;
						r.data.status = 'done';
						this.fileList.push(r.data);
						this.spinning = false;
						this.$emit('success', this.fileList);
					}
				})
				.catch(error => {
					this.result.push(error);
					this.spinning = false;
					console.log('失败', error);
				});
			this.uploading = true;
		}
	}
};
</script>

<style scoped></style>
