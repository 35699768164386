<template>
	<a-modal v-model="show" title="点评信息" :width="700" @ok="doSubmit" @cancel="_close" centered destroyOnClose>
		<a-layout>
			<div class="content-header">
				<a-affix :offset-top="0" style="width: 100%;">
					<a-row type="flex" justify="space-between">
						<a-col span="12">
							<a-button v-if="is" type="primary" @click="visible = true">添加点评信息</a-button>
						</a-col>
					</a-row>
				</a-affix>
			</div>
		</a-layout>
		<a-table :scroll="{ x: '100%' }" :style="{ background: '#FFF' }" size="small" :columns="is ? columns : filcolumns" ref="list" :data-source="data" :loading="loading" :bordered="false" :pagination="false" row-key="id">
			<!--内容-->
			<template slot-scope="row" slot="content">
				{{ row.content }}
			</template>

			<!--操作-->
			<template slot-scope="text, row, index" slot="action">
				<a-button-group shape="circle" size="small">
					<a-tooltip v-if="row.is" title="删除"><a-button icon="delete" @click="delRow(row, index)"></a-button></a-tooltip>
				</a-button-group>
			</template>
		</a-table>

		<a-modal title="添加点评信息" :visible="visible" @ok="handleOk" @cancel="handleCancel">
			<a-form-model :model="formData" ref="fromData" :rules="rules">
				<a-form-model-item label="点评信息" prop="content">
					<a-input v-model="formData.content"></a-input>
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</a-modal>
</template>

<script>
import apiUtil from '@/common/apiUtil';
import selectStaff from '@/components/common/select-staff.vue';
import upInput from '@/components/common/upInput.vue';
import utils from '@/common/utils';
import request from '@/config/request';

export default {
	components: { upInput, selectStaff },
	mounted() {
		// console.log(apiUtil.getUser());
	},
	data() {
		return {
			show: false,
			loading: false,
			is: true,
			visible: false,
			organList: apiUtil.getOrganList(),
			data: [],
			editcontent: '',
			staffId: apiUtil.getUser().staffId,
			formData: {
				content: '',
				reportId: ''
			},
			columns: [
				{ title: '点评人', dataIndex: 'name', align: 'center', ellipsis: true, width: 100 },
				{ title: '点评内容', scopedSlots: { customRender: 'content' }, align: 'center', ellipsis: true },
				{ title: '操作', scopedSlots: { customRender: 'action' }, width: 100 }
			],
			filcolumns: [
				{ title: '点评人', dataIndex: 'name', width: 100, align: 'center', ellipsis: true },
				{ title: '点评内容', dataIndex: 'content', align: 'center', ellipsis: true }
			],
			rules: { content: [{ required: true, message: '请输入点评信息' }] }
		};
	},
	methods: {
		...apiUtil,
		_show(reportId, is) {
			if (is == '本') {
				this.is = false;
			}
			this.show = true;
			this.formData.reportId = reportId;
			this.getList();
		},
		getList() {
			this.loading = true;
			this.http
				.post(`/platform/work-report/comment/${this.formData.reportId}`)
				.then(ret => {
					ret.data.forEach(item => {
						if (item.staffId == this.staffId) {
							item.is = true;
						}
					});
					this.data = ret.data;
					console.log(this.data);
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
				});
		},
		doRefresh() {
			this.getList();
		},
		// 删除
		delRow(row, index) {
			utils.confirm('您确认要删除此点评吗?').then(() => {
				utils.showSpin();
				request.delete('/platform/work-report/comment/del/' + row.id).then(ret => {
					this.getList();
				});
			});
		},
		_close() {
			this.show = false;
		},

		doSubmit() {
			this.show = false;
		},
		handleOk() {
			this.$refs.fromData.validate(valid => {
				if (valid) {
					utils.showSpin();
					this.http
						.post('/platform/work-report/comment/save', this.formData)
						.then(ret => {
							if (ret.code == 200) {
								utils.closeSpin();
								this.visible = false;
								this.utils.success('操作成功').then(() => {
									this.getList();
									this.formData.content = '';
								});
							}
						})
						.catch(err => {
							utils.closeSpin();
							this.formData.content = '';
							console.log(err);
						});
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		handleCancel() {
			this.visible = false;
			this.formData.content = '';
		}
	}
};
</script>

<style scoped>
.demo-drawer-footer {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	border-top: 1px solid #e8e8e8;
	padding: 10px 16px;
	text-align: right;
	background: #fff;
}

.avatar-uploader > .ant-upload {
	width: 128px;
	height: 128px;
}

.ant-upload-select-picture-card i {
	font-size: 32px;
	color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
	margin-top: 8px;
	color: #666;
}
</style>
